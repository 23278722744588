import styled from "styled-components"
import colours from "../config/colours"


const RichText = styled.div`
  font-family: ${props => props?.titleFont ? '"Quattrocento Sans", Alice, Helvetica, sans-serif' : '"Quattrocento Sans", Alice, Helvetica, sans-serif'};
  line-height: ${props => props?.lineHeight ? props.lineHeight : 'inherit'};
  font-size: ${props => props?.size || 'inherit'};
  font-style: ${props => props?.italic ? 'italic' : 'normal'};
  color: rgb(${props => props?.colour || colours.black});
  p {
    margin: ${props => props.margin ? props.margin : '0 0 1rem 0'};
  }
  a {
    color: rgba(${colours.darkturquoise});
  }
`

export default RichText