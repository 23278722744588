export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const devices = {
  mobileS: `min-width: ${breakpoints.mobileS}`,
  mobileM: `min-width: ${breakpoints.mobileM}`,
  mobileL: `min-width: ${breakpoints.mobileL}`,
  tablet: `min-width: ${breakpoints.tablet}`,
  laptop: `min-width: ${breakpoints.laptop}`,
  laptopL: `min-width: ${breakpoints.laptopL}`,
  desktop: `min-width: ${breakpoints.desktop}`,
  desktopL: `min-width: ${breakpoints.desktop}`
};