import React from "react"
import styled from "styled-components"
import colours from "../../config/colours"
import Nav from "../Nav"
import ContextConsumer from "../../context/LanguageContext"

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(${colours.green});
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
  z-index: 9;
  
  @media (max-width: 960px) {
    width: 100%;
  }
`

const Menu = ({ open, setOpen }) => {
  return (
    <ContextConsumer>
      {({ data }) => (
        <StyledMenu open={open}>
          <Nav currentLanguage={data.currentLanguage} setOpen={setOpen} />
        </StyledMenu>
        )}
    </ContextConsumer>
  )
}

export default Menu
