import React from "react"
import { motion } from "framer-motion"
import { InternalLink} from "../Link"
import { devices } from '../../config/media-queries'
import colours from "../../config/colours"
import { convertFocalPoint } from "../../utils/convertFocalPoint"
import styled from "styled-components"
import BackgroundImage from "../background-image"
import LinkArrow from "../../images/link-arrow.svg"
import Header from "../../layouts/header"
import RichText from "../rich-text"

const StyledCard = styled(motion.div)`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 0 1rem 0;
  border-radius: 20px;
  overflow: hidden;
    
  @media (${devices.tablet}) {
    width: ${props => props.cardWidth || '100%'};
    margin-bottom: 0;
    min-height: 300px;
  }  
`

const ImageWrapper = styled.div`
  height: 150px;
  flex-shrink: 0;
  @media (${devices.tablet}) {
    min-height: 150px;
  }
`

const TextWrapper = styled.div`
  position: relative;
  height: auto;
  min-height: 100%;
  padding: 1rem 1rem 5rem 1rem;
  color: rgb(${colours.white});
  background: rgb(${props => colours[props.bgColour] || 'gray'});
  
  @media (${devices.laptop}) {
    min-height: 60%;
  }
`

const LinkSection = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  opacity: 0.6;
`


const Card = props => {
  const {
    bgColour,
    cardWidth,
    focalPoint,
    src,
    pageUrl,
    summary,
    title
  } = props

  const pageSlug = `/${pageUrl}`

  return (
    <StyledCard
      cardWidth={cardWidth}
      whileHover={{
        scale: 1.007,
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)'
      }}
    >
      <ImageWrapper>
        <BackgroundImage
          src={src}
          position={convertFocalPoint(focalPoint)}
        />
      </ImageWrapper>

      <TextWrapper bgColour={bgColour}>
        <Header level={4} margin={'0 0 0.25rem 0'}>{title}</Header>
        <RichText colour={colours.white} lineHeight={1.4} size="16px">{summary}</RichText>
      </TextWrapper>

      <LinkSection>
        <InternalLink to={pageSlug}>
          <LinkArrow />
        </InternalLink>
      </LinkSection>
    </StyledCard>
  )
}


export default Card;
