/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useRef, Fragment } from "react"
import ContextConsumer, { ContextProvider } from "../context/LanguageContext"
import styled from "styled-components"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from 'uuid'
import { useOnClickOutside } from "../hooks/useOnClickOutside"
import colours from "../config/colours"
import Cards from "../components/Cards"
import Container from "./container"
import Footer from "./footer"
import Section from "./section"
import { InternalLink } from "../components/Link"
import SVGWrapper from "./svg-wrapper"
import LogoBlack from "../images/eivh-logo-black.svg"
import OuterSection from "./outer-section"
import LanguageSwitcher from "../components/LanguageSwitcher"
import Burger from "../components/Burger"
import Menu from "../components/Menu"

const Page = styled.div`
  color: ${colours.darkGray};
   
  a {
    color: ${colours.darkturquoise};
  }
`

const EmbedContainer = styled.div`
  margin: 0 auto;
  width: 300px;
  height: 130px;
  border-radius: 15px;
  overflow: hidden;
`

const Layout = ({ children }) => {
  // Nav Menu state functionality
  const [open, setOpen] = useState(false)

  // Click outside functionality (used for nav menu)
  const node = useRef()
  useOnClickOutside(node, () => setOpen(false))

  useEffect(() => {
    if (open) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.right = `0`
      document.body.style.left = `0`
    }
    if (!open) {
      document.body.style.position = ''
      document.body.style.top = ''
    }
  }, [open, setOpen])

  return (
    <Page id="page">
      <ContextProvider>
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>
        <LanguageSwitcher />
        <Container
          bgColour={colours.skyBlue}
          gradient
        >
          <SVGWrapper width="250px" margin="0 auto">
            <InternalLink to="/">
              <LogoBlack />
            </InternalLink>
          </SVGWrapper>
          <Fragment key={uuidv4()}>
            {children}
          </Fragment>
          <OuterSection background={[`antiqueWhite`, `cream`]} >
            <Section>
              <EmbedContainer className="facebook-container" />
            </Section>
            <Section padding="0 2rem">
              <ContextConsumer>
                {({ data }) => (
                  <Cards currentLanguage={data.currentLanguage} />
                )}
              </ContextConsumer>
            </Section>
          </OuterSection>
        </Container>
        <Footer />
      </ContextProvider>
    </Page>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
