import PropTypes from "prop-types"
import React from "react"
import { format } from 'date-fns'
import styled from "styled-components"
import Section from "./section"
import SVGWrapper from "./svg-wrapper"
import LogoWhite from '../images/eivh-logo-white.svg'
import RichText from "../components/rich-text"
import colours from "../config/colours"
import Social from "../components/Social"

const FooterWrapper = styled.footer`
  background: rgb(${colours.turquoise});
  color: rgb(${colours.white});
`

const FooterLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

const FooterMeta = styled.div`
  p {
    margin: 0;
  }
`

const Footer = () => (
  <FooterWrapper>
    <Section padding="2rem">
      <FooterLayout>
        <FooterMeta>
          <RichText size="14px" titleFont colour={colours.white}>
            <p>
              Copyright &copy; {format(new Date(), 'y')} Efail Isaf Village Hall. All rights reserved.
              <br />
              Registered Charity number 1107108
            </p>
          </RichText>
          <Social colour={`white`} margin={`2rem 0 0 0`} size={`30px`} />
        </FooterMeta>
        <SVGWrapper width="150px" margin={`0 0 0 2rem`}>
          <LogoWhite />
        </SVGWrapper>
      </FooterLayout>
    </Section>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  noUnderline: PropTypes.bool,
}

Footer.defaultProps = {
  siteTitle: ``,
  noUnderline: false,
}

export default Footer
