import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Social from "../Social"
import { InternalLink } from "../Link"
import {v4 as uuidv4 } from 'uuid'
import LogoBlack from "../../images/eivh-logo-black.svg"
import SVGWrapper from "../../layouts/svg-wrapper"
import colours from "../../config/colours"

const NavList = styled.ul`
  list-style: none;
  margin: 0 3rem;
  font-weight: 300;
  
  li:first-child {
    margin-bottom: 2rem;
  }

  a {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.25rem;
    color: rgb(${colours.black});
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: 960px) {
      font-size: 1.5rem;
      text-align: center;
    }
  
    &:hover {
      color: rgb(${colours.white});
    }
  }
`


const Nav = props => {
  const craftData = useStaticQuery(graphql`
    query NavQuery {
      craftapi {
        entriesEN: entries (section: "pages" siteId: "1") {
          slug
          title
        }
        entriesCY: entries (section: "pages" siteId: "2") {
          slug
          title
        }
      }
    }
  `)

  const navData = () => {
    if (props.currentLanguage === 'en') {
      return craftData.craftapi.entriesEN
    }
    return craftData.craftapi.entriesCY
  }

  return (
    <>
      <NavList role="navigation">
        <li>
          <InternalLink
            to="/"
            onClick={props => props.setOpen(false)}
          >
            <SVGWrapper width="150px" margin="0 0 0 -13px">
              <LogoBlack />
            </SVGWrapper>
          </InternalLink>
        </li>

        {navData().map(entry => {
          const pageSlug = `/${entry.slug}`

          return (
            <li key={uuidv4()}>
              <InternalLink
                to={pageSlug}
                onClick={props => props.setOpen(false)}
              >
                {entry.title}
              </InternalLink>
            </li>
          )
        })}
      </NavList>

      <Social margin={`2rem 3rem 0 3rem`} />
    </>
  )
}

export default Nav
