import styled from "styled-components"

const SVGWrapper = styled.div`
  margin: ${props => props.margin || 'auto'};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width || 'auto'};
  line-height: 1;
  
  svg {
    width: 100%;
    height: auto;
  }
`

export default SVGWrapper