import React, { Component } from "react"

const saveToSession = (key, value) => {
  sessionStorage.setItem(key, value)
}

const readFromSession = (key) => {
  if (typeof window !== `undefined` && sessionStorage.getItem(key)) {
    return sessionStorage.getItem(key)
  }
  return undefined
}
const defaultContextValue = {
  data: {
    // set your initial data shape here
    currentLanguage: readFromSession('currentLanguage') || 'en'
  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class ContextProvider extends Component {
  constructor() {
    super()
    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  componentDidMount() {
    this.setData({currentLanguage: readFromSession('currentLanguage') || 'en'})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.currentLanguage !== this.state.data.currentLanguage) {
      saveToSession('currentLanguage', this.state.data.currentLanguage)
      this.setData({currentLanguage: this.state.data.currentLanguage})
    }
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      }
    }))
  }

  render() {
    const { children } = this.props

    return (
      <Provider value={this.state}>{children}</Provider>
    )
  }
}

export { Consumer as default, ContextProvider }
