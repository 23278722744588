import React from "react"
import styled from "styled-components"
import colours from "../../config/colours"
import ContextConsumer from "../../context/LanguageContext"

const SwitchWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  
  button {
    cursor: pointer;
  }
`

const Button = styled.button`
    border: none;
    padding: 0.3rem 0.75rem;
    border-radius: 5px;
    color: rgb(${colours.white});
    background: ${props => props.bgColour ? `rgb(${colours[props.bgColour]})` : `rbg(${colours.palePink})`};
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 0.05rem;
`

const LanguageSwitcher = () => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        data.currentLanguage && (
          <SwitchWrapper>
            {data.currentLanguage === 'cy' &&
            <Button
              bgColour="palePink"
              onClick={() => set({ currentLanguage: 'en' })}
              role="button"
            >
              EN
            </Button>
            }
            {data.currentLanguage === 'en' &&
              <Button
              bgColour="palePink"
              onClick={() => set({ currentLanguage: 'cy'})}
              role="button"
              >
              CY
              </Button>
            }
          </SwitchWrapper>
        )
      )}
    </ContextConsumer>
  )
}

export default LanguageSwitcher