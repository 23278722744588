import styled from "styled-components"
import { breakpoints, devices } from "../config/media-queries"
import colours from "../config/colours"

const Section = styled.section`
  margin: ${props => props?.margin || '0 auto'};
  padding: ${props => props?.padding || '0 2rem 1.5rem 2rem'};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'};
  color: ${colours.darkGray};
  
  a {
    color: ${colours.darkturquoise};
  }
 
  @media (${devices.laptop}) {
    padding-left: 0;
    padding-right: 0;
    max-width: ${breakpoints.laptop};
  }
`

export default Section
