import styled from "styled-components"
import colours from "../config/colours"
import { breakpoints } from '../config/media-queries'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${props => props?.padding || '2rem 0 0 0'};
  background: linear-gradient(
    rgba(${props => props?.bgColour || colours.white}, 0.5), 
    rgba(${props => props?.bgColour || colours.white}, 1)
  );
  
  @media (max-width: ${breakpoints.mobileL}) {
    padding-top: 6rem;
  }
`

export default Container
