// This function will take the focal point provided by Craft, and
// convert it into a CSS compatible value (for background-position)
export const convertFocalPoint = focalPoint => {
  if (focalPoint) {
    return focalPoint.map(point => (
      `${(point * 100)}%`
    ))
  }
  return undefined
}
