import styled from "styled-components"
import { Link } from "gatsby"
import React from "react"

// Reminder, only use InternalLink for internal page linking!
// This component adapted by Shep to accommodate both internal and external links
// https://www.gatsbyjs.org/docs/gatsby-link/

export const InternalLink = styled(({color, noUnderline, ...props}) => <Link {...props} />)`
  color: ${props => props.color ? props.color : "cadetblue"};
  text-decoration: ${props => props.noUnderline ? "none" : "underline"};
  text-align: ${props => props.align || 'left'};
  width: 100%;
`

export const ExternalLink = styled.a`
  color: ${props => props.color ? props.color : "cadetblue"};
  text-decoration: ${props => props.noUnderline ? "none" : "underline"}
`
