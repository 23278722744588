import React from 'react'
import styled from "styled-components"
import TwitterIcon from '../../images/icon-tw-dark.svg'
import FacebookIcon from '../../images/icon-fb-dark.svg'


const SocialList = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-end;
  margin: ${({ margin }) => margin ? margin : '0'};
  
  li {
    margin: 0 1.25rem 0 0;
    line-height: 0;
  }
  
  svg {
    width: ${({ size }) => size ? size : '50px'};
    height: auto;
    
    path {
      fill: ${({ colour }) => colour || 'black'};
      transition: fill 0.3s linear;
    }
    
    &:hover {
      path {
        fill: white;
      }
    }
  }
`

const Social = ({ colour, margin, size }) => {
  return (
    <SocialList colour={colour} margin={margin} size={size}>
      <li>
        {/*<a href="#">*/}
          <TwitterIcon />
        {/*</a>*/}
      </li>
      <li>
        <a href="https://www.facebook.com/EfailIsafVillageHall">
          <FacebookIcon />
        </a>
      </li>
    </SocialList>
  )
}

export default Social

