const colours = {
  black: '0,0,0',
  darkGray: '30,30,30',
  cream: '250,236,225',
  white: '255,255,255',
  antiqueWhite: '240, 235, 235',
  skyBlue: '191,221,234',
  turquoise: '58,180,180',
  darkturquoise: '48,160,160',
  paleTurquoise: '144,210,216',
  orange: '207,132,63',
  green: '119,173,76',
  hotPink: '188,90,155',
  palePink: '246,166,178',
  red: '178,82,82',
}

export default colours