import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuidv4 } from 'uuid';
import { devices } from '../../config/media-queries'
import styled from "styled-components"
import Card from "../Card"

const StyledCards = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  
  @media (${devices.tablet}) {
    flex-flow: row;
  }
`

const Cards = ({ currentLanguage }) => {
  const craftData = useStaticQuery(graphql`
    query CardsQuery {
      craftapi {
        entriesEN: entries (section: "pages" siteId: "1") {
          title
          uri
          ... on CraftAPI_pages_standardPage_Entry {
            featuredImage {
              src: url (transform:"desktop")
              srcMobile: url (transform:"mobile")
              srcTablet: url (transform:"tablet")
              srcDesktop: url (transform:"desktop")
              focalPoint
              title
            }
            summary
            backgroundColour
          }
        }
        entriesCY: entries (section: "pages" siteId: "2") {
          title
          uri
          ... on CraftAPI_pages_standardPage_Entry {
            featuredImage {
              src: url (transform:"desktop")
              srcMobile: url (transform:"mobile")
              srcTablet: url (transform:"tablet")
              srcDesktop: url (transform:"desktop")
              focalPoint
              title
            }
            summary
            backgroundColour
          }
        }
      }
    }
  `)

  const pageData = () => {
    if (currentLanguage === 'en') {
      return craftData.craftapi.entriesEN
    }
    return craftData.craftapi.entriesCY
  }

  const calculateCardWidth = () => {
    const noOfEntries = pageData().length
    switch (noOfEntries) {
      case 1 : return '100%'
      case 2 : return '47%'
      case 3 : return '30%'
      case 4 : return '23%'
      default : return '100%'
    }
  }

  return (
    <StyledCards>
      {pageData() && pageData().map(entry => (
        <Card
          bgColour={entry.backgroundColour}
          cardWidth={calculateCardWidth()}
          focalPoint={entry.featuredImage[0]?.focalPoint}
          src={entry.featuredImage[0]?.srcTablet}
          summary={entry.summary}
          title={entry.title}
          key={uuidv4()}
          pageUrl={entry.uri}
        />
      ))}
    </StyledCards>
  )
}

export default Cards