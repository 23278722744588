import styled from "styled-components"
import colours from "../config/colours"

const OuterSection = styled.section`
  width: 100%;
  margin: 0;
  padding: 1.5rem 0;
  // background can be passed in as a single colour (string) or gradient (array)
  background: ${props => Array.isArray(props.background)
    ? 'linear-gradient( rgba(' + colours[props.background[0]] + '), rgba(' + colours[props.background[1]] + ') );'
    : props.background ? 'rgba(' + colours[props.background] + ');' : 'rgba(' + colours.white + ');'
  }
`

export default OuterSection
