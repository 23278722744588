import React from "react"
import styled from "styled-components"

const BackgroundImageStyle = styled.div`
  height: 100%;
  width: 100%;
`

const BackgroundImage = (props) => {
  const {
    position,
    src,
  } = props

  const backgroundStyle = {
    backgroundImage: `url(${src})`,
    backgroundPositionX: position ? position[0] : 'center',
    backgroundPositionY: position ? position[1] : 'center',
    backgroundSize: 'cover',
  }

  return (
    <BackgroundImageStyle
      style={backgroundStyle}
    />
  )
}

export default BackgroundImage
